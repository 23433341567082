<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue"><i class="fas fa-users margin-r" aria-hidden="true"></i>Teams</h3>
          <div class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div style="display:inline !important">
                <div class="d-flex justify-content-between">
                  <h2 class="card-title ">
                    <button class="button-1 w-100 btn btn-md btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"> Add </button>
                  </h2>
                  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">New Team</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <form ref="ProjectForm" @submit.prevent="PostAddTeam()" method="post" class="row" align="left">
                            <div class="col-md-12">
                              <label class="form-label">Team</label>
                              <input class="form-control" type="text" v-model="TeamPost.name" required />
                            </div>
                            <div class="col-md-12">
                              <label class="form-label">Team color</label>
                              <input class="form-control" type="color" v-model="TeamPost.color" required />
                            </div>
                            <div class="modal-footer">
                              <button :disabled="Form.submit"  type="submit" class="button-1 btn btn-primary">Save changes</button>
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Tabla-->
              <div class="table-responsive">
                <table id="team-table" class="table table-striped responsive">
                  <thead>
                    <tr class="text-start">
                      <th scope="col">
                        <span class="text-blue">Team</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="mb-special">
                    <tr class="text-start" v-if="ListTeams.status != 'false'" v-for="(obj, key, index) in ListTeams.list" :key="index">
                      <td class="text-start">{{obj.name}}</td>
                      <td class="text-center">
                        <div class="d-flex justify-content-around">
                          <a href="#" class="text-blue" id="dropdownMenuActions" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                          <ul class="drop-actions dropdown-menu" aria-labelledby="dropdownMenuActions">
                            <li>
                              <router-link :to="'team/edit/'+obj.id" class="dropdown-item rounded">Edit Team</router-link>
                            </li>
                            <li>
                              <router-link :to="'team/addmember/'+obj.id" class="dropdown-item rounded">Manage Members</router-link>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Request'; 
  import "datatables.net-dt/js/dataTables.dataTables"
  import "datatables.net-dt/css/jquery.dataTables.min.css"
  import $ from 'jquery'; 

  export default
  {
    name: 'Team',
    data: () => (
    {
      TeamPost: 
      {
        name: "",
        color:"#000000",
        message:"",
        status_post: ""  
      },
      ListTeams: 
      {
        status:"false",
        list: {}
      },
      Form: 
      {
        submit: false
      },
    }),
    methods: 
    {
      PostAddTeam() 
      {
        this.Form.submit = true

        Api.post("/team/insert",this.TeamPost).then((result) => 
        {
          if(result.data.status == "true")
          {
            this.TeamPost.status_post = result.data.status
            this.TeamPost.message = result.data.msg;

            var table = $('#team-table').DataTable()
            table.destroy()

            this.$moshaToast( ''+this.$filters.OnlyLetters(this.TeamPost.message)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
         
            this.$nextTick(function() 
            {
              setTimeout( () => 
              {
                this.TeamPost.name = ""
                this.TeamPost.color = "#000000"
                this.TeamPost.message = ""
                this.TeamPost.status_post = ""
                this.Form.submit = false
              }, 5000);

              this.GetTeam()
            })
          }

          if(result.data.status == "false")
          {
            setTimeout( () => 
            {
              this.ProjectPost.name = ""
              this.ProjectPost.status = ""
              this.ProjectPost.message = ""
              this.ProjectPost.status_post = ""
            }, 3000);

            this.TeamPost.status_post = result.data.status
            this.TeamPost.message = result.data.msg;
          }
        })    
      },
      GetTeam() 
      {
        Api.post("/team/list",'').then((result) => 
        {
          this.ListTeams.status = result.data.status

          if(this.ListTeams.status == "true")
          {
            this.ListTeams.list = result.data.team;
            this.$nextTick(() => 
            {
              this.InitDatatable()
            })
          }
        })
      },
      UpdateTeamStatus(id,status) 
      {
        if(status==1) { var ProjectStatus=0 }
        if(status==0) { var ProjectStatus=1 }

        let Post = 
        {
          id:id,
          status:TeamsStatus
        };
    
        Api.post("/team/status", Post).then((result) => 
        {
          if(result.data.status == "true")
          {
            this.RefreshDatatable()
          }
        })
      },
      InitDatatable() 
      {
        var table = $('#team-table').DataTable( 
        {
          dom: 'lftip',
          //dom: 'Blftip',
          responsive: true,
          destroy: true,
          lengthChange: false,
          aaSorting: [ [0,'asc'] ]
        });
      },
      RefreshDatatable() 
      {
        Api.post("/team/list",'').then((result) => 
        {
          this.ListTeams.status = result.data.status

          if(this.ListTeams.status == "true")
          {
            this.ListTeams.list = result.data.team;
          }
        })
      }
    },
    mounted()
    {
      this.GetTeam()
    }
  }
</script>

<style scoped>
.table-striped>tbody>tr:nth-of-type(odd) 
{
  --bs-table-accent-bg: rgb(255 255 255 / 5%);
  color: #424242;
}

table.dataTable.no-footer 
{
  border-bottom: 1px solid #f1f1f1;
}
</style>
